import { useSelector } from 'react-redux';
import '../css/app.css'
import '../css/main.css'
import '../css/common.css'
import '../css/payment.css'
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom"
import axios from 'axios';
import { MessageModal } from '../component/modal/modal';

function Header() {
    let location = useLocation();
    let navigate = useNavigate();

    let HeaderTitle = useSelector((state) => { return state.HeaderTitle })

    const [hamBtn, setHamBnt] = useState(false)
    const [logoutBtn, setLogoutBtn] = useState(false)
    const [message, setMessage] = useState("")

    const onSubmit = async () => {
        if (window.confirm("로그아웃을 하시겠습니까?")) {
            await axios.post(process.env.REACT_APP_API + "/cp/logout", {
            }, {
                withCredentials: true,
            }).then((response) => {
                let tmp = response?.data
                if (tmp?.code < 2000) {
                    setLogoutBtn(false)
                    navigate('/')
                }
                else {
                    setMessage({ msg: "로그아웃에 실패했습니다." })
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    return (
        <div style={{ display: location.pathname == '/' ? "none" : "" }}>
            <MessageModal message={message} />
            <div className={`mobile-menu md:hidden`}>
                <div className="mobile-menu-bar">
                    <Link to={`/dashboard`} className="main_logo flex mr-auto gnb-link">
                        <img alt="East Commerce Logo" className="w-32" src="/img/antpay01.jpg" />
                    </Link>
                    <a href="/" className="menu on" data-id="notice">공지</a>
                    <a href="/" className="menu" data-id="payment">수기</a>
                    <a href="/" className="menu" data-id="paylist">내역</a>
                    <div className="mobile-menu-wrap">
                        <a href="javascript:;" className={`mobile-menu-toggler  ${hamBtn ? "mobile-menu--active" : ""}`} onClick={() => setHamBnt(e => !e)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="bar-chart-2" data-lucide="bar-chart-2" className="lucide lucide-bar-chart-2 w-8 h-8 text-white transform -rotate-90"><line x1="18" y1="20" x2="18" y2="10"></line><line x1="12" y1="20" x2="12" y2="4"></line><line x1="6" y1="20" x2="6" y2="14"></line></svg>
                        </a>

                        <div className={`dropdown-user-info w-56  ${hamBtn ? "show" : ""}`}>
                            <ul className="dropdown-content bg-primary/80 before:block before:absolute before:bg-black before:inset-0 before:rounded-md before:z-[-1] text-white">
                                <li className="p-2">
                                    <div className="font-medium">200256 님</div>
                                </li>
                                <li>
                                    <hr className="dropdown-divider border-white/[0.08]" />
                                </li>
                                <li>
                                    <hr className="dropdown-divider border-white/[0.08]" />
                                </li>
                                <li>
                                    <a href="" id="logout-btn" className="dropdown-item hover:bg-white/5" data-kind="pc"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="toggle-right" data-lucide="toggle-right" className="lucide lucide-toggle-right w-4 h-4 mr-2"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="16" cy="12" r="3"></circle></svg> 로그아웃 </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="top-bar-boxed h-[70px] md:h-[65px] z-[51] border-b border-white/[0.08] mt-12 md:mt-0 -mx-3 sm:-mx-8 md:-mx-0 px-3 md:border-b-0 relative md:fixed md:inset-x-0 md:top-0 md:px-8 md:bg-gradient-to-b md:from-slate-100 md:to-transparent dark:md:from-darkmode-700">
                <div className="h-full flex items-center -intro-x h-[45px]" style={{ float: "left" }}>
                    <a href="" className="logo -intro-x hidden md:flex  xl:w-[180px] xl:mr-0 mr-5 block" style={{ left: "20px" }}>
                        <img alt="ant" className="logo__image w-32" src="/img/antpay01.jpg" />
                    </a>
                    <nav aria-label="breadcrumb" className="-intro-x h-[45px] mr-auto">
                        <ol className="breadcrumb breadcrumb-light">
                            <li className="breadcrumb-item pg_title" id="pg_title" value="">{HeaderTitle?.mainTitle ?? ""}</li>
                            <li className="breadcrumb-item active" id="pg_sub_title" aria-current="page">{HeaderTitle?.subTitle ?? ""}</li>
                        </ol>
                    </nav>
                </div>
                <div className="" style={{ float: "right" }}>
                    <div className="h-full flex items-center intro-x h-[45px] mr-5" style={{ float: "left" }}>
                        <span style={{ color: "white" }}>200256 님</span>
                    </div>
                    <div className="intro-x dropdown w-8 h-[45px] flex items-center dropdown_user" style={{ float: "left" }}>
                        <div className="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110" role="button" aria-expanded="false" data-tw-toggle="dropdown">
                            <img id="user-info-toggle" alt="Midone - HTML Admin Template" src="/img/profile-6.jpg" onClick={() => setLogoutBtn(e => !e)} />
                        </div>
                        {
                            logoutBtn
                                ?
                                <div className="dropdown-user-info w-56">
                                    <ul className="dropdown-content bg-primary/80 before:block before:absolute before:bg-black before:inset-0 before:rounded-md before:z-[-1] text-white">
                                        <li className="p-2">
                                            <div className="font-medium">200256</div>
                                            <div className="text-xs text-white/60 mt-0.5 dark:text-slate-500">가맹점</div>
                                        </li>
                                        <li>
                                            <hr className="dropdown-divider border-white/[0.08]" />
                                        </li>
                                        <li>
                                            <hr className="dropdown-divider border-white/[0.08]" />
                                        </li>
                                        <li>
                                            <Link id="logout-user" className="dropdown-item hover:bg-white/5" data-kind="pc" onClick={() => onSubmit()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="toggle-right" data-lucide="toggle-right" className="lucide lucide-toggle-right w-4 h-4 mr-2"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="16" cy="12" r="3"></circle>
                                                </svg>
                                                로그아웃
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                :
                                ""
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
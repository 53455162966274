import { useEffect, useState } from 'react';
import './css/app.css'
import './css/common.css'

const createArr = (n) => {
    const iArr = new Array(n);
    for (var i = 0; i < n; i++)  iArr[i] = i + 1;
    return iArr;
}

function Page({ initialData, page, setPage, maxFirstPage }) {
    useEffect(() => {
        if (page === 1) setBlockNum(0)
    }, [page])

    let len = 0
    if (initialData) {
        if (Array.isArray(initialData)) {
            len = initialData.length;
        } else {
            len = initialData;
        }
    }
    let maxPage = 0
    if (parseInt(Number(len) % maxFirstPage) === 0) {
        maxPage = parseInt(Number(len) / maxFirstPage)
    }
    else {
        maxPage = parseInt(Number(len) / maxFirstPage) + 1;
    }

    let pageLimit = Math.min(parseInt(Number(len) / maxFirstPage) + 1, 5);

    const [blockNum, setBlockNum] = useState(Math.floor((page - 1) / 5));
    let lastBlock = Math.ceil(maxPage / 5) - 1

    const v = Number(blockNum * pageLimit);
    const iArr = createArr(maxPage);
    let pArr = iArr.slice(v, Number(pageLimit) + v);

    const firstPage = () => {
        setPage(1)
        setBlockNum(0)
    }

    const lastPage = () => {
        setPage(maxPage)
        setBlockNum(Math.ceil(maxPage / pageLimit) - 1)
    }

    const prevPage = () => {
        setBlockNum(n => n - 1);
        setPage((blockNum) * pageLimit);
    }
    const nextPage = () => {
        setBlockNum(n => n + 1);
        setPage((blockNum + 1) * pageLimit + 1);
    }

    const nowPage = (n) => {
        setPage(n)
    }

    return (
        <div className="tabulator-footer">
            <span className="tabulator-paginator">
                {
                    page <= 5
                        ?
                        ""
                        :
                        <>
                            <button className="tabulator-page" type="button" role="button" aria-label="First Page" title="First Page" data-page="first" disabled="" onClick={firstPage}>First</button>
                            <button className="tabulator-page" type="button" role="button" aria-label="Prev Page" title="Prev Page" data-page="prev" disabled="" onClick={prevPage}>Prev</button>
                        </>
                }
                <span className="tabulator-pages">
                    {pArr.map(num => (
                        page == num ?
                            (<button key={num} className="tabulator-page active" type="button" role="button">
                                {num}
                            </button>) :
                            (<button key={num} className="tabulator-page" type="button" role="button" onClick={() => { nowPage(num) }}>
                                {num}
                            </button>)
                    ))}
                </span>
                {
                    blockNum === lastBlock || lastBlock === -1
                        ?
                        ""
                        :
                        <>
                            <button className="tabulator-page" type="button" role="button" aria-label="Next Page" title="Next Page" data-page="next" onClick={nextPage}>Next</button>
                            <button className="tabulator-page" type="button" role="button" aria-label="Last Page" title="Last Page" data-page="last" onClick={lastPage}>Last</button>
                        </>
                }
            </span>
        </div>
    );
}

export default Page;
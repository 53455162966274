import '../css/app.css'
import '../css/main.css'
import '../css/common.css'
import '../css/payment.css'
import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
    LayoutDashboard, NotebookPen, Volume2, PhoneCall, CreditCard, FilePenLine
} from 'lucide-react';
import { useSelector } from 'react-redux';

function Sidebar() {
    let location = useLocation();
    let Current_Location = useSelector((state) => { return state.Current_Location.value });

    const [windowtypeM, setWindowtypeM] = useState(false)
    const [cur, setCur] = useState(0)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1280) setWindowtypeM(true)
            else setWindowtypeM(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (Current_Location) {
            if (Current_Location == "대시보드") { setCur(0) }
            else if (Current_Location == "거래조회") { setCur(1) }
            else if (Current_Location == "공지사항") { setCur(2) }
            else if (Current_Location == "정산내역") { setCur(3) }
        }
    }, [Current_Location])

    return (
        <div className="flex overflow-hidden" style={{ display: location.pathname == '/' ? "none" : "" }}>
            <nav className="side-nav">
                <ul>
                    <li>
                        <Link to={`/dashboard`} className={`side-menu ${cur == 0 ? "side-menu--active" : ""}`} data-id="main">
                            <div className="side-menu__icon">
                                <CreditCard />
                            </div>
                            <div className="side-menu__title">
                                <span>수기결제</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/paylist`} className={`side-menu ${cur == 1 ? "side-menu--active" : ""}`} data-id="main">
                            <div className="side-menu__icon">
                                <NotebookPen />
                            </div>
                            <div className="side-menu__title">
                                <span>거래조회</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/settlement`} className={`side-menu ${cur == 3 ? "side-menu--active" : ""}`} data-id="main">
                            <div className="side-menu__icon">
                            <FilePenLine />
                            </div>
                            <div className="side-menu__title">
                                <span>정산내역</span>
                            </div>
                        </Link>
                    </li>
                    {/* <li>
                        <Link to={`/notice`} className={`side-menu ${cur == 2 ? "side-menu--active" : ""}`} data-id="main">
                            <div className="side-menu__icon">
                                <Volume2 />
                            </div>
                            <div className="side-menu__title">
                                <span>공지사항</span>
                            </div>
                        </Link>
                    </li> */}

                    {/* <div className="customer-info">
                        {
                            windowtypeM
                                ?
                                <>
                                    <div className="cust-text">CUSTOMER CENTER</div>
                                    <div className="cust-phone">
                                        <PhoneCall /><span>1600-4406</span>
                                    </div>
                                    <div className="cust-work"><span>평일</span>09:00 - 18:00</div>
                                    <div className="cust-rest"><span>휴무</span>토/일/공휴일 휴무</div>
                                </>
                                :
                                <PhoneCall />
                        }
                    </div> */}
                </ul>
            </nav>
        </div>
    )
}

export default Sidebar;
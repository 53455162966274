const removeZeros = (num) => {
    let strNum = num.toString();
    strNum = strNum.replace(/(\.\d*?)0+$/, "$1");
    strNum = strNum.replace(/\.$/, "");

    return Decimalization(strNum);
}

const Decimalization = (n) => {
    let str = String(n).toUpperCase()
    let arr = str.split('E-')
    let result;
    if (arr[1]) {
        result = (Number(arr[0]) / Math.pow(10, Number(arr[1]))).toFixed(Number(arr[1]) + arr[0].length - 2)
    }
    else {
        result = arr[0]
    }

    return result
}

const changeNum = (n) => {
    let num = String(n)
    let arr = num.split('.')
    let result;

    if (arr[1]) {
        result = Number(arr[0]).toLocaleString() + "." + arr[1]
    }
    else {
        result = Number(arr[0]).toLocaleString()
    }

    return result;
}

function formatDateTime(input) {
    if (!/^\d{14}$/.test(input)) {
        throw new Error("입력은 정확히 14자리의 숫자여야 합니다.");
    }

    const year = input.substr(2, 2);
    const month = input.substr(4, 2);
    const day = input.substr(6, 2);
    const hour = input.substr(8, 2);
    const minute = input.substr(10, 2);
    const second = input.substr(12, 2);

    return `${year}-${month}-${day} ${hour}:${minute}`;
}

export { removeZeros, Decimalization, changeNum, formatDateTime }
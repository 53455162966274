import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/main.css'
import './css/common.css'
import './css/payment.css'
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom"
import { change_Current_Location, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { CountAmount, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Litepicker from 'litepicker';
import 'litepicker/dist/css/litepicker.css';
import { SearchDate, SearchRange, SearchSelect, SearchSelectText, SearchBtn } from './component/search/search';
import { formatDateTime, changeNum } from './common/function';

function Paylist() {
    let navigate = useNavigate();
    const [page, setPage] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(10)
    const [amount, setAmount] = useState([1000000, 50000])

    const [listData, setListData] = useState([])
    const [combineData, setCombineData] = useState([])
    const [clickData, setClickData] = useState([])
    const [reload, setReload] = useState(false)
    const [modal, setModal] = useState(false)
    const [modal1, setModal1] = useState(false)

    let dispatch = useDispatch()

    useEffect(() => {
        dispatch(change_Current_Location("거래조회"))
        dispatch(changeHeaderTitle({
            mainTitle: "거래조회",
            subTitle: ""
        }))
    }, [])

    const [formData, setFormData] = useState({
        MID: 'kpay51000m',
        moid: '',
        orderSeqAutoGenFlg: true,
        Amt: '',
        BuyerName: '',
        GoodsName: '',
        BuyerHpTel1: '',
        BuyerHpTel2: '',
        BuyerHpTel3: '',
        card_num1: '',
        card_num2: '',
        card_num3: '',
        card_num4: '',
        CardPw: '',
        ExpMonth: '',
        ExpYear: '',
        card_gubun: '개인',
        IDNo: '',
        CardQuota: '00',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // 기간 포맷
    const inputRef = useRef(null);
    const pickerRef = useRef(null); // Litepicker 인스턴스를 저장할 ref

    useEffect(() => {
        pickerRef.current = new Litepicker({
            element: inputRef.current,
            lang: "ko",
            singleMode: false, // 날짜 범위 선택
            format: 'YYYY/MM/DD', // 날짜 형식
            numberOfMonths: 2, // 두 달 표시
            numberOfColumns: 2, // 두 열로 나누어 표시
            showWeekNumbers: true, // 주 번호 표시
            dropdowns: {
                minYear: 2020,
                maxYear: 2030,
                months: true,
                years: true,
            },
            setup: (picker) => {
                // 이벤트 리스너 등록
                picker.on('selected', (start, end) => {
                    setPreDate(start.format('YYYY/MM/DD'));
                    setNextDate(end.format('YYYY/MM/DD'));
                });
            }
        });

        return () => {
            pickerRef.current?.destroy(); // 컴포넌트 언마운트 시 파괴
        };
    }, []);

    // 날짜범위 포맷
    const [preDate, setPreDate] = useState("")
    const [nextDate, setNextDate] = useState("")

    // 날짜를 설정하는 함수
    const setDateRange = (startDate, endDate) => {
        setPreDate(startDate)
        setNextDate(endDate)
        pickerRef.current?.setDateRange(startDate, endDate);
        onSubmitSearch()
    };

    const onSubmitSearch = () => {
        setPage(1)
        setReload(e => !e)
    }

    useEffect(() => {
        ListData()
    }, [page, maxFirstPage, reload])

    const ListData = async () => {
        await axios.get(process.env.REACT_APP_API + "/cp/transaction/list", {
            withCredentials: true,
            params: {
                page: page,
                limit: maxFirstPage,
                startDate: preDate,
                endDate: nextDate,
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                setListData(tmp?.data ?? [])
                setAmount([tmp?.data?.trxAmount ?? 0, tmp?.data?.cancelAmount ?? 0])
            }
            // else if (tmp?.code === 9999) {
            //     navigate('/')
            // }
        }).catch(error => {
            console.error(error);
        });
    }

    const tableHeaderData = [
        { "no": 1, "text": "NO", "width": "50px", "textAlign": "center" },
        { "no": 2, "text": "거래일/요청일", "width": "150px", "textAlign": "center" },
        { "no": 3, "text": "거래상태", "width": "100px", "textAlign": "center" },
        { "no": 4, "text": "거래금액", "width": "100px", "textAlign": "center" },
        { "no": 5, "text": "잔여금액", "width": "100px", "textAlign": "center" },
        { "no": 6, "text": "할부", "width": "100px", "textAlign": "center" },
        { "no": 7, "text": "결제서비스", "width": "120px", "textAlign": "center" },
        { "no": 8, "text": "결제구분", "width": "100px", "textAlign": "center" },
        { "no": 9, "text": "구매자명", "width": "100px", "textAlign": "center" },
        { "no": 10, "text": "상품명", "width": "150px", "textAlign": "center" },
        { "no": 11, "text": "주문번호", "width": "150px", "textAlign": "center" },
        { "no": 12, "text": "거래상세", "width": "80px", "textAlign": "center" },
        { "no": 13, "text": "거래확인서", "width": "80px", "textAlign": "center" },
    ];

    useEffect(() => {
        let tableBodyData = [];
        if (listData) {
            let list = listData?.items ?? []
            for (let i = 0; i < list.length; i++) {
                let data = list[i]

                tableBodyData.push([
                    { "no": 1, "text": data.id, "width": "50px", "textAlign": "left" },
                    { "no": 2, "text": data.id, "width": "150px", "textAlign": "left" },
                    { "no": 3, "text": formatDateTime(`${data.tranDate}${data.tranTime}`), "width": "100px", "textAlign": "center" },
                    { "no": 4, "text": data.itemName === "" ? "-" : data.itemName, "width": "100px", "textAlign": `${data.itemName === "" ? 'center' : 'left'}` },
                    { "no": 5, "text": data.cardAuthNo, "width": "100px", "textAlign": "center" },
                    { "no": 6, "text": `${changeNum(Number(data.amount))}원`, "width": "100px", "textAlign": "right" },
                    { "no": 7, "text": data.userName === "" ? "-" : data.userName, "width": "120px", "textAlign": `${data.itemName === "" ? 'center' : 'left'}` },
                    { "no": 8, "text": data.cardName.substring(0, 2), "width": "100px", "textAlign": "center" },
                    { "no": 9, "text": data.quota === "00" ? "." : `${data.quota}개월`, "width": "100px", "textAlign": "center" },
                    { "no": 10, "text": data.cancelTranDate ? formatDateTime(`${data.cancelTranDate}${data.cancelTranTime}`) : '', "width": "150px", "textAlign": "center" },
                    { "no": 11, "text": data.cancelTranDate ? formatDateTime(`${data.cancelTranDate}${data.cancelTranTime}`) : '', "width": "150px", "textAlign": "center" },
                    {
                        "no": 12,
                        "text": (
                            <div className='pointer list-text-center' onClick={() => { setClickData(data); setModal(true) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-archive w-4 h-4" style={{ color: "#0064ff" }}>
                                    <path d="M20 9v9a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9m16-5H4a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm-10 9h4"></path>
                                </svg>
                            </div>
                        ),
                        "width": "80px",
                        "textAlign": "center"
                    },
                    {
                        "no": 13,
                        "text": (
                            <div className='pointer list-text-center' onClick={() => { setClickData(data); setModal1(true) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-clipboard w-4 h-4" style={{ color: "#0a6e0a" }}>
                                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                                    <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                                </svg>
                            </div>
                        ),
                        "width": "80px",
                        "textAlign": "center"
                    },
                ])
            }
            setCombineData(tableBodyData)
        }
    }, [listData]);

    return (
        <div class="content" id="contents">
            <div class="paylist">
                <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
                    <div class="form_filter w-full">
                        <SearchDate type={"날짜"} inputRef={inputRef} setDateRange={setDateRange} />
                        <button id="tabulator-html-filter-go" name="searchPayBtn" type="button" class="btn btn-primary  searchPayBtn" onClick={() => onSubmitSearch()}>검색</button>
                    </div>
                </form>
                <div class="intro-y box mt-5 md:p-2 sm:p-0">
                    <div className="tabulator_summary_wrap item_wrap">
                        <TotalAmount confirm_amt={amount[0]} canceled_amt={amount[1]} />
                    </div>
                    <List tableHeaderData={tableHeaderData} tableBodyData={listData?.total ?? 0} filter_tableBodyData={combineData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />
                </div>
            </div>
        </div>
    )
}

export default Paylist;
import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/main.css'
import './css/common.css'
import './css/payment.css'
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom"
import { change_Current_Location, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { CountAmount, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Litepicker from 'litepicker';
import 'litepicker/dist/css/litepicker.css';
import { SearchDate, SearchRange, SearchSelect, SearchSelectText, SearchBtn } from './component/search/search';
import dayjs from 'dayjs';
import { formatDateTime, changeNum } from './common/function';

function Dashboard() {
    let navigate = useNavigate();
    const [page, setPage] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(10)
    const [amount, setAmount] = useState([1000000, 50000])
    const [modal, setModal] = useState(false)
    const [modal1, setModal1] = useState(false)

    const [listData, setListData] = useState([])
    const [combineData, setCombineData] = useState([])
    const [clickData, setClickData] = useState([])
    const [reload, setReload] = useState(false)

    let dispatch = useDispatch()

    useEffect(() => {
        dispatch(change_Current_Location("대시보드"))
        dispatch(changeHeaderTitle({
            mainTitle: "수기결제",
            subTitle: ""
        }))
    }, [])

    const [formData, setFormData] = useState({
        MID: 'kpay51000m',
        moid: '',
        orderSeqAutoGenFlg: true,
        Amt: '',
        BuyerName: '',
        GoodsName: '',
        BuyerHpTel1: '',
        BuyerHpTel2: '',
        BuyerHpTel3: '',
        card_num1: '',
        card_num2: '',
        card_num3: '',
        card_num4: '',
        CardPw: '',
        ExpMonth: '',
        ExpYear: '',
        card_gubun: '개인',
        IDNo: '',
        CardQuota: '00',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // 기간 포맷
    const inputRef = useRef(null);
    const pickerRef = useRef(null); // Litepicker 인스턴스를 저장할 ref

    useEffect(() => {
        pickerRef.current = new Litepicker({
            element: inputRef.current,
            lang: "ko",
            singleMode: false, // 날짜 범위 선택
            format: 'YYYY/MM/DD', // 날짜 형식
            numberOfMonths: 2, // 두 달 표시
            numberOfColumns: 2, // 두 열로 나누어 표시
            showWeekNumbers: true, // 주 번호 표시
            dropdowns: {
                minYear: 2020,
                maxYear: 2030,
                months: true,
                years: true,
            },
            setup: (picker) => {
                // 이벤트 리스너 등록
                picker.on('selected', (start, end) => {
                    setPreDate(start.format('YYYY/MM/DD'));
                    setNextDate(end.format('YYYY/MM/DD'));
                });
            }
        });

        return () => {
            pickerRef.current?.destroy(); // 컴포넌트 언마운트 시 파괴
        };
    }, []);

    // 날짜범위 포맷
    const [preDate, setPreDate] = useState(dayjs().format('YYYY/MM/DD'))
    const [nextDate, setNextDate] = useState(dayjs().format('YYYY/MM/DD'))

    useEffect(() => {
        pickerRef.current?.setDateRange(preDate, nextDate);
    }, [])

    // 날짜를 설정하는 함수
    const setDateRange = (startDate, endDate) => {
        setPreDate(startDate)
        setNextDate(endDate)
        pickerRef.current?.setDateRange(startDate, endDate);
        onSubmitSearch()
    };

    const onSubmitSearch = () => {
        setPage(1)
        setReload(e => !e)
    }

    const onSubmitSugi = async () => {
        await axios.post(process.env.REACT_APP_API + "/cp/sugi/payment", {
            itemName: formData.GoodsName,
            amount: formData.Amt,
            userName: formData.BuyerName,
            billInfo: formData.card_num1 + formData.card_num2 + formData.card_num3 + formData.card_num4,
            expirePeriod: formData.ExpYear + formData.ExpMonth,
            quota: formData.CardQuota,
            userPhone: formData.BuyerHpTel1 + formData.BuyerHpTel2 + formData.BuyerHpTel3
        }, {
            withCredentials: true,
        }).then((response) => {
            console.log(response.data)
            let tmp = response?.data
            if (tmp?.code < 2000) {
                setFormData({
                    MID: 'kpay51000m',
                    moid: '',
                    orderSeqAutoGenFlg: true,
                    Amt: '',
                    BuyerName: '',
                    GoodsName: '',
                    BuyerHpTel1: '',
                    BuyerHpTel2: '',
                    BuyerHpTel3: '',
                    card_num1: '',
                    card_num2: '',
                    card_num3: '',
                    card_num4: '',
                    CardPw: '',
                    ExpMonth: '',
                    ExpYear: '',
                    card_gubun: '개인',
                    IDNo: '',
                    CardQuota: '00',
                });
            }
        }).catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        ListData()
    }, [])

    const ListData = async () => {
        await axios.get(process.env.REACT_APP_API + "/cp/sugi/list", {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                setListData(tmp?.data ?? [])
            }
            // else if (tmp?.code === 9999) {
            //     navigate('/')
            // }
        }).catch(error => {
            console.error(error);
        });
    }

    const tableHeaderData = [
        { "no": 1, "text": "NO", "width": "50px", "textAlign": "center" },
        { "no": 2, "text": "거래일/요청일", "width": "150px", "textAlign": "center" },
        { "no": 3, "text": "거래상태", "width": "100px", "textAlign": "center" },
        { "no": 4, "text": "거래금액", "width": "100px", "textAlign": "center" },
        { "no": 5, "text": "잔여금액", "width": "100px", "textAlign": "center" },
        { "no": 6, "text": "할부", "width": "100px", "textAlign": "center" },
        { "no": 7, "text": "결제서비스", "width": "120px", "textAlign": "center" },
        { "no": 8, "text": "결제구분", "width": "100px", "textAlign": "center" },
        { "no": 9, "text": "구매자명", "width": "100px", "textAlign": "center" },
        { "no": 10, "text": "상품명", "width": "150px", "textAlign": "center" },
        { "no": 11, "text": "주문번호", "width": "150px", "textAlign": "center" },
        { "no": 12, "text": "거래상세", "width": "80px", "textAlign": "center" },
        { "no": 13, "text": "거래확인서", "width": "80px", "textAlign": "center" },
    ];

    useEffect(() => {
        let tableBodyData = [];
        if (listData) {
            let list = listData.items ?? []

            let pre = preDate.replace(/\//g, '')
            let next = nextDate.replace(/\//g, '')

            let confirm = 0
            let cancel = 0

            for (let i = 0; i < list.length; i++) {
                let data = list[i]

                if (Number(pre) <= Number(data.tranDate) && Number(data.tranDate) <= Number(next)) {
                    tableBodyData.push([
                        { "no": 1, "text": data.id, "width": "50px", "textAlign": "left" },
                        { "no": 2, "text": data.id, "width": "150px", "textAlign": "left" },
                        { "no": 3, "text": formatDateTime(`${data.tranDate}${data.tranTime}`), "width": "100px", "textAlign": "center" },
                        { "no": 4, "text": data.itemName === "" ? "-" : data.itemName, "width": "100px", "textAlign": `${data.itemName === "" ? 'center' : 'left'}` },
                        { "no": 5, "text": data.cardAuthNo, "width": "100px", "textAlign": "center" },
                        { "no": 6, "text": `${changeNum(Number(data.amount))}원`, "width": "100px", "textAlign": "right" },
                        { "no": 7, "text": data.userName === "" ? "-" : data.userName, "width": "120px", "textAlign": `${data.itemName === "" ? 'center' : 'left'}` },
                        { "no": 8, "text": data.cardName.substring(0, 2), "width": "100px", "textAlign": "center" },
                        { "no": 9, "text": data.quota === "00" ? "." : `${data.quota}개월`, "width": "100px", "textAlign": "center" },
                        { "no": 10, "text": data.cancelTranDate ? formatDateTime(`${data.cancelTranDate}${data.cancelTranTime}`) : '', "width": "150px", "textAlign": "center" },
                        { "no": 11, "text": data.cancelTranDate ? formatDateTime(`${data.cancelTranDate}${data.cancelTranTime}`) : '', "width": "150px", "textAlign": "center" },
                        {
                            "no": 12,
                            "text": (
                                <div className='pointer list-text-center' onClick={() => { setClickData(data); setModal(true) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-archive w-4 h-4" style={{ color: "#0064ff" }}>
                                        <path d="M20 9v9a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9m16-5H4a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm-10 9h4"></path>
                                    </svg>
                                </div>
                            ),
                            "width": "80px",
                            "textAlign": "center"
                        },
                        {
                            "no": 13,
                            "text": (
                                <div className='pointer list-text-center' onClick={() => { setClickData(data); setModal1(true) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-clipboard w-4 h-4" style={{ color: "#0a6e0a" }}>
                                        <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                                        <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                                    </svg>
                                </div>
                            ),
                            "width": "80px",
                            "textAlign": "center"
                        },
                    ])

                    if (data.cancelTranDate) {
                        cancel += Number(data.amount)
                    }
                    else {
                        confirm += Number(data.amount)
                    }
                }
            }

            setAmount([confirm, cancel])
            setCombineData(tableBodyData)
        }
    }, [listData, reload]);


    let filterData = combineData.slice((page - 1) * maxFirstPage, (page) * maxFirstPage)

    return (
        <div className="flex overflow-hidden" >
            {modal ? <ReceiptInfo setModal={setModal} clickData={clickData} /> : ""}
            <div class="content" id="contents">
                <div class="payment">
                    <div class="inner-wrap h-full">
                        <div class="content-wrap h-full" style={{ padding: "0" }}>
                            <form className="content-item payForm active" name="payForm" id="payForm" method="post" acceptCharset="UTF-8" autoComplete="off">
                                <div className="intro-y box">
                                    <h2 className="title w-full">결제하기</h2>
                                    <input type="hidden" value={formData.MID} id="MID" name="MID" />

                                    <div className="input hidden">
                                        <label htmlFor="regular-form-3" className="form-label">주문번호</label>
                                        <input
                                            id="moid"
                                            name="moid"
                                            type="text"
                                            className="form-control"
                                            placeholder="주문번호 입력"
                                            value={formData.moid}
                                            onChange={handleChange}
                                        />
                                        <span className="pos_aps_r" style={{ float: 'right' }}>
                                            <input
                                                type="checkbox"
                                                name="orderSeqAutoGenFlg"
                                                id="orderSeqAutoGenFlg"
                                                checked={formData.orderSeqAutoGenFlg}
                                                onChange={handleChange}
                                            />
                                            &nbsp;자동생성
                                        </span>
                                    </div>

                                    <div className="input">
                                        <label htmlFor="GoodsName" className="form-label">상품정보(이름/금액)</label>
                                        <div className="multiple">
                                            <input
                                                id="GoodsName"
                                                name="GoodsName"
                                                type="text"
                                                className="form-control"
                                                placeholder="상품 입력"
                                                maxLength="20"
                                                value={formData.GoodsName}
                                                onChange={handleChange}
                                            />
                                            <span>/</span>
                                            <input
                                                id="Amt"
                                                name="Amt"
                                                type="number"
                                                className="form-control"
                                                placeholder="금액 입력"
                                                maxLength="9"
                                                value={formData.Amt}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="input">
                                        <label htmlFor="Amt" className="form-label">결제요청금액</label>
                                    </div> */}

                                    <div className="input">
                                        <label htmlFor="BuyerName" className="form-label">구매자명</label>
                                        <input
                                            id="BuyerName"
                                            name="BuyerName"
                                            type="text"
                                            className="form-control"
                                            placeholder="이름 입력"
                                            maxLength="20"
                                            value={formData.BuyerName}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="input">
                                        <label htmlFor="BuyerHpTel1" className="form-label">휴대폰번호</label>
                                        <div className="multiple">
                                            <input
                                                id="BuyerHpTel1"
                                                name="BuyerHpTel1"
                                                type="tel"
                                                className="form-control"
                                                placeholder="010"
                                                maxLength="3"
                                                value={formData.BuyerHpTel1}
                                                onChange={handleChange}
                                            />
                                            <span>-</span>
                                            <input
                                                id="BuyerHpTel2"
                                                name="BuyerHpTel2"
                                                type="tel"
                                                className="form-control"
                                                placeholder="0000"
                                                maxLength="4"
                                                value={formData.BuyerHpTel2}
                                                onChange={handleChange}
                                            />
                                            <span>-</span>
                                            <input
                                                id="BuyerHpTel3"
                                                name="BuyerHpTel3"
                                                type="tel"
                                                className="form-control"
                                                placeholder="0000"
                                                maxLength="4"
                                                value={formData.BuyerHpTel3}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="input_w_70 input">
                                        <label htmlFor="card_num1" className="form-label">신용카드번호</label>
                                        <div className="multiple">
                                            <input
                                                name="card_num1"
                                                id="card_num1"
                                                type="number"
                                                className="form-control card_num_set"
                                                maxLength="4"
                                                value={formData.card_num1}
                                                onChange={handleChange}
                                            />
                                            <span>-</span>
                                            <input
                                                name="card_num2"
                                                id="card_num2"
                                                type="number"
                                                className="form-control card_num_set"
                                                maxLength="4"
                                                value={formData.card_num2}
                                                onChange={handleChange}
                                            />
                                            <span>-</span>
                                            <input
                                                name="card_num3"
                                                id="card_num3"
                                                type="number"
                                                className="form-control card_num_set"
                                                maxLength="4"
                                                value={formData.card_num3}
                                                onChange={handleChange}
                                            />
                                            <span>-</span>
                                            <input
                                                name="card_num4"
                                                id="card_num4"
                                                type="number"
                                                className="form-control card_num_set"
                                                maxLength="4"
                                                value={formData.card_num4}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="input_w_30 input">
                                        <label htmlFor="CardPw" className="form-label">카드 비밀번호</label>
                                        <input
                                            id="CardPw"
                                            name="CardPw"
                                            type="password"
                                            className="form-control"
                                            maxLength="2"
                                            placeholder="앞 2자리"
                                            value={formData.CardPw}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="input_w_55 input">
                                        <label htmlFor="ExpMonth" className="form-label">카드 유효기간 (월/년)</label>
                                        <div className="multiple">
                                            <input
                                                id="ExpMonth"
                                                name="ExpMonth"
                                                type="number"
                                                className="form-control"
                                                maxLength="2"
                                                placeholder="month"
                                                value={formData.ExpMonth}
                                                onChange={handleChange}
                                            />
                                            <span>/</span>
                                            <input
                                                id="ExpYear"
                                                name="ExpYear"
                                                type="number"
                                                className="form-control"
                                                maxLength="2"
                                                placeholder="year"
                                                value={formData.ExpYear}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="input_w_50 input">
                                        <label htmlFor="card_gubun" className="form-label">개인/법인</label>
                                        <select
                                            id="card_gubun"
                                            name="card_gubun"
                                            className="form-select mt-2 sm:mr-2"
                                            value={formData.card_gubun}
                                            onChange={handleChange}
                                        >
                                            <option value="개인">개인</option>
                                            <option value="법인">법인</option>
                                        </select>
                                    </div> */}

                                    <div className="input_w_50 input">
                                        <label htmlFor="IDNo" className="form-label">생년월일 6자리</label>
                                        <input
                                            id="IDNo"
                                            name="IDNo"
                                            type="number"
                                            className="form-control"
                                            maxLength="6"
                                            placeholder="생년월일 6자리"
                                            value={formData.IDNo}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="input_w_50 input">
                                        <label htmlFor="CardQuota" className="form-label">할부기간</label>
                                        <select
                                            id="CardQuota"
                                            name="CardQuota"
                                            className="form-select mt-2 sm:mr-2"
                                            value={formData.CardQuota}
                                            onChange={handleChange}
                                        >
                                            <option value="00">일시불</option>
                                            <option value="01">01개월</option>
                                            <option value="02">02개월</option>
                                            <option value="03">03개월</option>
                                            <option value="04">04개월</option>
                                            <option value="05">05개월</option>
                                            <option value="06">06개월</option>
                                        </select>
                                    </div>

                                    <div className="payBt">
                                        <input id="ec_pay_btn" className="btn btn-primary mt-3" value="요청" onClick={() => onSubmitSugi()} />
                                    </div>
                                </div>
                            </form>

                            <div class="content-item intro-y box p-2 mt-5 cardInfo overflow-hidden">
                                <h2 class="title">수기 결제 내역</h2>
                                <div class=" mb-4">
                                    <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
                                        <div class="form_filter w-full">
                                            <SearchDate type={"날짜"} inputRef={inputRef} setDateRange={setDateRange} />
                                            <button id="tabulator-html-filter-go" name="searchPayBtn" type="button" class="btn btn-primary  searchPayBtn" onClick={() => onSubmitSearch()}>검색</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="tabulator_summary_wrap">
                                    <TotalAmount confirm_amt={amount[0]} canceled_amt={amount[1]} />
                                </div>

                                <List tableHeaderData={tableHeaderData} tableBodyData={combineData} filter_tableBodyData={filterData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ReceiptInfo({ setModal, clickData }) {

    console.log(clickData)

    let date = clickData.createdAt.split('T')
    let date1 = date[1].split('.')

    return (
        <div id="receipt-modal" className="modal" tabindex="-1" >
            <div className="modal-dialog">
                <div className="modal-content marginTop30 modal_sty type-receipt receipt-modal-content">
                    <div className="modal-header receipt-modal-header">
                        <h2 className="font-medium text-base mr-auto modal_title_sty">결제 정보</h2>
                        <button className="dropdown-toggle btn px-1 py-1 box modal_btn close_btn_sty" aria-expanded="false" data-tw-toggle="dropdown" onClick={() => setModal(false)}>
                            <span className="w-4 h-4 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="plus" className="lucide lucide-plus w-4 h-4" data-lucide="plus">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <form id="modal-form">
                        <div className="modal-body receipt-modal-body">
                            <div className="box-sales" id="box-sales">
                                {
                                    // 취소시 취소 이미지
                                    clickData?.cancelType
                                        ?
                                        <div className="box-result" style={{ top: "50%", position: "absolute" }}>
                                            <div className="inner">
                                                <img src="/img/bg_cancel.png" alt="취소" />
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                                <div id="header">
                                    <h2>신용카드<br />매출전표</h2>
                                    <h1><img src="/img/ant_gray.png" alt="앤트페이" /></h1>
                                </div>
                                <div className="list-info-box">
                                    <div className="box-gap">
                                        <div className="gap">
                                            <div className="tt"><h3>결제정보</h3></div>
                                            <ul>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">카드번호</span>
                                                        <span className="right-text" data-id="CARD_NO">{clickData.cardNo}</span>
                                                    </div>
                                                    <div className="inner">
                                                        <span className="left-text">카드종류</span>
                                                        <span className="right-text" data-id="CARD_NAME">{clickData.cardName}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">거래구분</span>
                                                        <span className="right-text" data-id="STATE_CD">{clickData?.returnMsg ?? "승인"}</span>
                                                    </div>
                                                    <div className="inner">
                                                        <span className="left-text">할부개월</span>
                                                        <span className="right-text" data-id="CARD_SELL_MON">{clickData.quota == "00" ? "일시불" : `${clickData.quota}개월`}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">거래일시</span>
                                                        <span className="right-text" data-id="APP_DT" id="APP_DT">{`${date[0]} ${date1[0]}`}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">영수증번호</span>
                                                        <span className="right-text" data-id="RECEIPT_NO">{clickData.billInfo}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="box-gap type-2">
                                        <div className="gap">
                                            <div className="tt"><h3>구매자정보</h3></div>
                                            <ul>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">구매자</span>
                                                        <span className="right-text" data-id="USER_NAME">{clickData.userName}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">승인번호</span>
                                                        <span className="right-text" id="APP_NO" data-id="APP_NO">{clickData.cardAuthNo}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">주문번호</span>
                                                        <span className="right-text" data-id="ORDER_NO">{clickData.orderId}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">상품명</span>
                                                        <span className="right-text" data-id="PRODUCT_NAME">{clickData.itemName}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="gap">
                                            <div className="tt"><h3>결제금액정보</h3></div>
                                            <ul>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">과세금액</span>
                                                        <span className="right-text" id="PG_AMT_BEFORE">{Number(clickData.amount) - Math.floor(Number(clickData.amount) / 11)}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">부가세</span>
                                                        <span className="right-text" id="PG_VAT_AMT">{Math.floor(Number(clickData.amount) / 11)}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">면세금액</span>
                                                        <span className="right-text">0</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">합계</span>
                                                        <span className="right-text" data-id="AMOUNT">{clickData.amount}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="box-gap">
                                        <div className="gap">
                                            <div className="tt"><h3>이용상점정보</h3></div>
                                            <ul>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">상점명</span>
                                                        <span className="right-text" id="MER_NAME" data-id="MER_NAME">{ }</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner" style={{ width: "100%" }}>
                                                        <span className="left-text">URL</span>
                                                        <span className="right-text"></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">이용문의<br /><span className="txt-s">(구매/취소/배송)</span></span>
                                                        <span className="right-text" id="MER_HP" data-id="MER_HP">010-1234-5678</span>
                                                    </div>
                                                    <div className="inner">
                                                        <span className="left-text">대표자</span>
                                                        <span className="right-text" id="MER_CEO" data-id="MER_CEO">홍길동</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner" style={{ width: "100%" }}>
                                                        <span className="left-text">사업장주소</span>
                                                        <span className="right-text" id="MER_BIZ_ADDRESS" data-id="MER_BIZ_ADDRESS">서울특별시 강남구</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="box-gap">
                                        <div className="gap">
                                            <div className="tt"><h3>결제서비스업체정보</h3></div>
                                            <ul>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">결제대행사명</span>
                                                        <span className="right-text" data-id="NAME">주식회사 앤트페이먼츠</span>
                                                    </div>
                                                    <div className="inner">
                                                        <span className="left-text">사업자번호</span>
                                                        <span className="right-text" data-id="COMP_NO">127-86-6****</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">대표자명</span>
                                                        <span className="right-text" data-id="CEO">당한민</span>
                                                    </div>
                                                    <div className="inner">
                                                        <span className="left-text">가맹점번호</span>
                                                        <span className="right-text"></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner" style={{ width: "100%" }}>
                                                        <span className="left-text">사업장주소</span>
                                                        <span className="right-text" data-id="ADDR">인천광역시 연수구 인천타워대로 323, A동 21층 2107-2108호(송도동, 송도 센트로드)</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="infoFooter">
                                    부가가치세법 제46조 3항에 따라 신용카드 매출전표도 매입세금계산서로 사용할 수 있으며, 부가가치세법 제33조 2항에 따라 별도의 세금계산서를 교부하지 않습니다.
                                    <div className="box-btn">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button id="receipt_print_btn" type="button" className="btn" style={{ marginRight: "3px", backgroundColor: "#d2e4fb", fontWeight: "600" }}>저장</button>
                        <button type="button" className="btn w-20 modal_btn receipt_close_btn" onClick={() => setModal(false)}>닫기</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;